import { ModalProvider } from '@/context/modal'
import { OnLoadAction } from '@/shared/api-generated-types'
import DialogAction from '@/widgets/DialogAction'

export const HandleOnLoadAction = (action: OnLoadAction): JSX.Element | null => {
  if (action?.__typename === 'Dialog') {
    const dialog = action
    return (
      <ModalProvider initialState={{ isOpen: true }}>
        <DialogAction staticDialog={dialog} />
      </ModalProvider>
    )
  }

  return null
}
