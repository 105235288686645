import { FragDialog } from '@/widgets/DialogAction/query'
import { gql } from 'urql'

export const FragOnLoadAction = gql`
  ${FragDialog}

  fragment OnLoadAction on OnLoadAction {
    __typename
    ... on Dialog {
      ...Dialog
    }
  }
`
